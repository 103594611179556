import React from "react"
import tw, { styled } from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import WpAcfHeadlineModuleBlock from "@modules/WpAcfHeadlineModuleBlock"
import parse from "html-react-parser"
import { useBreakpoint } from "@hooks/useBreakpoint"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroContainer = styled.div`
  ${tw`relative flex flex-col justify-center w-full h-screen px-6 py-10 text-white bg-black font-primary md:px-12`}
`
const HeroImageContainer = styled.div`
  ${tw`absolute inset-0 w-full h-full`}
`
const PostTitle = styled.h1`
  ${tw`z-10 text-5xl font-black text-center text-white uppercase font-primary 2xl:text-10xl xl:text-9xl lg:text-8xl md:text-6xl`}
  text-shadow: 1px 1px 1px rgba(0,0,0,1);
`

const HeroImage = styled(GatsbyImage)`
  ${tw`absolute object-cover w-full h-full`}
  img {
    object-position: ${({ isMobile }) =>
      !!isMobile ? "65% calc(100% + 0px)" : "100% calc(30% + 0px)"};
  }
`

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allWpPage(filter: { title: { eq: "404" } }) {
        edges {
          node {
            id
            title
            blocks {
              dynamicContent
              originalContent
              saveContent
              ... on WpAcfHeadlineModuleBlock {
                acfHeadlineModuleBlock {
                  backgroundColor
                  headlineColor
                  headline
                  stickerPosition
                  subline
                  sublinePosition
                  sublineColor
                  headlineAsImage
                  headlineImage {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(width: 2000)
                      }
                    }
                  }
                  button {
                    target
                    title
                    url
                  }
                  sticker {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            acfTemplateHeaderImage {
              headerImageMobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const page = data.allWpPage.edges[0]?.node
  const { isScreenMd, isScreenSm } = useBreakpoint()
  const browser = typeof window !== "undefined" && window
  if (!page) return null
  if (!browser) return null
  return (
    <>
      <HeroContainer>
        <HeroImageContainer>
          {(isScreenMd || isScreenSm) && (
            <HeroImage
              isMobile={true}
              image={
                getImage(
                  page.acfTemplateHeaderImage?.headerImageMobile?.localFile
                    .childImageSharp
                ) ||
                getImage(page.featuredImage?.node?.localFile.childImageSharp)
              }
              alt=""
            />
          )}
          {!isScreenMd && !isScreenSm && page.featuredImage?.node && (
            <HeroImage
              isMobile={false}
              image={getImage(
                page.featuredImage?.node?.localFile.childImageSharp
              )}
              alt=""
            />
          )}
        </HeroImageContainer>
        <PostTitle>{parse(page.title)}</PostTitle>
      </HeroContainer>
      <WpAcfHeadlineModuleBlock moduleData={page.blocks[0]} key="404" />
    </>
  )
}

export default NotFoundPage
